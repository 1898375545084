import React from "react"
import { tv } from "tailwind-variants"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"

const buttonVariants = tv({
  base: "inline-block px-[0.9375rem] py-[0.6875rem] transition-colors duration-300 group",
  variants: {
    type: {
      primary:
        "text-ro-gray-50 bg-ro-red border border-ro-red hover:bg-ro-red-600 hover:border-ro-red-600 active:bg-ro-red-800 active:border-ro-red-800 focus-visible:outline focus-visible:outline-ro-white focus-visible:outline-1 focus-visible:-outline-offset-[3px]",
      primaryWhite:
        "text-ro-gray-900 bg-ro-white border border-ro-white hover:bg-ro-gray-75 hover:border-ro-gray-75 active:bg-ro-gray-200 active:border-ro-red-200 focus-visible:outline focus-visible:outline-ro-gray-900 focus-visible:outline-1 focus-visible:-outline-offset-[3px]",
      secondary:
        "text-ro-white bg-ro-gray-700 border border-ro-gray-700 hover:bg-ro-gray-750 hover:border-ro-gray-750 active:bg-ro-gray-500 active:border-ro-gray-500 focus-visible:outline focus-visible:outline-ro-red focus-visible:outline-2 focus-visible:-outline-offset-2",
      outlineRed:
        "text-ro-red-500 border border-ro-red hover:text-ro-gray-50 hover:bg-ro-red-600 hover:border-ro-red-600 active:text-ro-gray-50 active:bg-ro-red-700 active:border-ro-red-700 focus-visible:outline focus-visible:outline-ro-red focus-visible:outline-2 focus-visible:-outline-offset-2",
      outlineWhite:
        "text-ro-white border border-ro-white hover:text-ro-gray-900 hover:bg-ro-gray-50 hover:border-ro-gray-50 active:text-ro-gray-900 active:bg-ro-gray-200 active:border-ro-gray-200 focus-visible:outline focus-visible:outline-ro-white focus-visible:outline-2 focus-visible:-outline-offset-2",
    },

    state: {
      default: "",
      disabled: "pointer-events-none",
    },
  },
  compoundVariants: [
    {
      type: "primary",
      state: "disabled",
      class: "border-ro-gray-100 bg-ro-gray-100 text-ro-gray-300",
    },
    {
      type: "secondary",
      state: "disabled",
      class: "border-ro-gray-100 bg-ro-gray-100 text-ro-gray-300",
    },
    {
      type: "outlineRed",
      state: "disabled",
      class: "border-ro-gray-100 text-ro-gray-100",
    },
    {
      type: "outlineWhite",
      state: "disabled",
      class: "border-ro-gray-500 text-ro-gray-500",
    },
  ],
  defaultVariants: { type: "primary", state: "default" },
})

const Button = ({
  children,
  className,
  btnType = "primary",
  btnState = "default",
  link,
  target,
  ...props
}) => {
  let btnDisabledState = btnState === "disabled" ? { disabled: true } : {}

  if (link) {
    return (
      <Link
        className={buttonVariants({
          type: btnType,
          state: btnState,
          className: className,
        })}
        link={link}
        target={target}
        {...props}
      >
        {children}
      </Link>
    )
  } else {
    return (
      <button
        className={buttonVariants({
          type: btnType,
          state: btnState,
          className: className,
        })}
        {...btnDisabledState}
        {...props}
      >
        {children}
      </button>
    )
  }
}

export default Button
