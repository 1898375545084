import React from "react"
import SbEditable from "storyblok-react"
import HeadlineEnhanced from "@yumgmbh/gatsby-theme-yum-components/src/components/molecules/HeadlineEnhanced"
import ButtonGroup from "@yumgmbh/gatsby-theme-yum-components/src/components/molecules/ButtonGroup"

// ohne das zusätzliche div um die children funktioniert
// der Live-Edit innerhalb von Storyblock nicht mehr
const BlockWrapper = ({
  className,
  block,
  children,
  blockWidth = "container",
  blockDirection = "vertical",
  blockPadding = "py-block",
  showHeadline = "false",
  centerHeadline = "false",
  showButtons = "false",
}) => {
  blockWidth = block.block_width ? block.block_width : blockWidth
  blockDirection = block.block_type ? block.block_type : blockDirection
  const blockName = block.component
    ? block.component.replace(/_/g, "-")
    : "unknown-block"

  if (block?.block_bg === "bg-gray-50") {
    block.block_bg = "bg-ro-gray-50"
  }
  return (
    <SbEditable content={block}>
      <section
        className={`${className ? className : ""} ${blockName} ${
          block.block_bg ? block.block_bg : ""
        }`}
        id={block._uid}
      >
        <div className={`${blockPadding} block-w-${blockWidth}`}>
          <div
            className={`flex flex-row flex-wrap ${
              blockWidth !== "screen" ? "-mx-grid" : ""
            }`}
          >
            <div
              className={`w-full ${
                blockDirection === "horizontal" ? "lg:w-1/3" : ""
              } px-grid`}
            >
              {showHeadline !== "false" && (
                <HeadlineEnhanced
                  kicker={block.kicker}
                  headline={block.headline}
                  headlineLevel={block.headline_level}
                  subheadline={block.subheadline}
                  className={centerHeadline === "true" ? "text-center" : ""}
                />
              )}
              {showButtons !== "false" && (
                <ButtonGroup className="mt-8" buttons={block.buttons} />
              )}
            </div>

            <div
              className={`w-full ${
                blockDirection === "horizontal" ? "lg:w-2/3" : ""
              } ${blockWidth !== "screen" ? "px-grid" : ""}`}
            >
              {children}
            </div>
          </div>
        </div>
      </section>
    </SbEditable>
  )
}

export default BlockWrapper
